.file-input {
    position: relative;
    &--input-group {
        display: flex;
        align-items: center;
        justify-content: center;

        &--button {
            border: 2px dashed var(--primary-light);
            color: var(--blue-medium);
            padding: 0.5rem 0.75rem;
            &:hover {
                color: var(--primary-light);
            }
        }
        &--close {
            position: absolute;
            right: 0.75rem;
            max-width: 2.5rem;
            max-height: 2.5rem;
            color: var(--blue);
        }
    }
}
